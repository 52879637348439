<template>
  <div class="full-area has-image pausa-activa-wrapper"
    :class="`full-bg-${data.fields.category}`"
  >
    <audio ref="playerStart"><source :src="`${this.publicPath}/sounds/workout-start.mp3`" type="audio/mpeg" /></audio>
    <audio ref="playerCue"><source :src="`${this.publicPath}/sounds/workout-cue.mp3`" type="audio/mpeg" /></audio>
    <audio ref="playerEnd"><source :src="`${this.publicPath}/sounds/workout-end.mp3`" type="audio/mpeg" /></audio>
    <div class="overlay-close-button-wrapper" v-if="overlay" @click="quitOverlay()">
      <Icons class="icon" icon="close" size="48" color="#fff"></Icons>
    </div>
    <div class="repeat-button-wrapper" v-if="stage === 2" @click="startTraining()">
      <Icons class="icon" icon="reload" size="48" color="#fff"></Icons>
    </div>
    <div class="remarks-wrapper" v-if="(stage === 0 || stage === 1 ) && !overlay">
      <h1>{{this.data.fields.title}}</h1>
      <p class="">
        {{countdown < this.data.fields.countdown / 2 ? this.data.fields.swapInstructions || this.data.fields.remarks : this.data.fields.remarks}}<br>
        <span class="small" v-if="(stage === 0) && data.fields.youTubeEmbedCode">¿Tienes dudas?</span> <span class="video-hint small delay-entry delay-entry-quick" v-if="(stage === 0) && data.fields.youTubeEmbedCode" 
          @click="startVideo()"
          :class="{hide: stage === 1}"
        >ver video
        </span>
      </p>
    </div>
    <div class="video-overlay" v-if="overlay" @click="quitOverlay()">
      <div class="yt-embed" v-if="data.fields.youTubeEmbedCode" v-html="getVideoEmbed(data.fields.youTubeEmbedCode, data.fields.youTubeStartTime || 0)"></div>
    </div>
    <div class="pausa-picture" :style="{backgroundImage: `url(${data.fields.picture.fields.file.url})`}" v-if="stage === 0 || stage === 1">
    </div>
    <div class="change-workout-wrapper" v-if="data.fields.allowWorkoutChange">
        <el-button
          type="danger"
          @click="changeWorkout()"
          class="change-workout-button"
          :class="{hide: stage !== 0}"
        >Cambiar ejercicio
        </el-button>
    </div>
    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionTitle"
    >{{data.fields.questionTitle}}</h2>

    <content class="contentx countdown-wrapper delay-entry short-delay"
      :class="{'on-end': stage === 2, 'full-height': stage === 3}"
    >
      <div class="countdown"
        :class="{hide: stage != 1}"
      >
        {{countdown}}
      </div>
      <div class="action-button-wrapper" v-if="(stage === 0) && !overlay">
        <el-button
          type="danger"
          @click="startTraining()"
          class="go-button"
          :class="{hide: stage !== 0}"
        >¡Vamos!
        </el-button>
      </div>
      <div class="action-button-wrapper standoutx" :key="stage">
        <div class="" v-if="stage === 2">
          <h2 class="white">
            {{successMessage}}
          </h2>
          <h3 class="white">{{form.ratingQuestion}}</h3>
          <el-rate
            class="enlarge-icon"
            v-model="form.rating"
            :colors="['#333', '#666', '#fff']"
            void-color="#fff"
          ></el-rate>
          <el-button
            type="danger"
            @click="trainMore()"
            class="train-more-button"
            v-if="this.$store.state.survey.isPausaActiva && this.$store.state.pausaActiva.length < 2"
          >Hacer otro
          </el-button>
        </div>
        <div class="reasons delay-entry" v-if="stage === 3">
          <h2>¿Por qué?</h2>
          <div class="grid-wrapper delay-entry">
            <div
              :span="12"
              v-for="(item, key) in data.fields.reasons || reasons"
              class="option-box"
              :key="Math.random() + rand"
              :class="{'standout': form.reason === item}"
              @click="toggleThis(item, key)"
            >{{item}}<i class="el-icon-check icon-inside"></i></div>
          </div>
        </div>
      </div>
    </content>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'PausaActiva',

  props: [ 'data' ],

  components: {
    Icons: () => import('Components/base/Icons'),
    FeedbackScreen: () => import('Components/base/FeedbackScreen'),
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  data () {
    return {
      rand: 0,
      reasons: ["Demasiado fácil", "Demasiado difícil", "No entendí el ejercicio", "No tenía el equipo o espacio para hacerlo", "No me gustó", "Otro"],
      publicPath: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_URL : 'http://localhost:8080/',
      message: ["¡Buen trabajo!", "¡Excelente!", "Bien hecho.", "¡Maravilloso!"],
      feedback: '',
      locked: false,
      overlay: false,
      form: {
        repeats: 0,
        videoPlays: this.data.fields.youTubeEmbedCode ? 0 : -1,
        type: `pausaActiva`,
        identifier: this.data.fields.identifier,
        question: this.data.fields.title,
        category: this.data.fields.category,
        group: this.data.fields.group,
        id: this.data.sys.id,
        selected: [],
        rating: 0,
        ratingQuestion: this.data.fields.howWasItText || '¿Cómo te pareció este ejercicio?',
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      },
      stage: 0, //0: not started, 1: started, 2: time's up, 3: get feedback
      countdown: this.data.fields.countdown
    }
  },

  mounted () {
    if (this.$refs.player) {
      this.$refs.player.addEventListener('playing', this.isPlaying)
      this.$refs.player.addEventListener('ended', this.isPlaying)
    }
    if (this.data.fields.autoStart) {
      setTimeout(() => {
        this.startTraining()
      }, 2000)
    }
  },

  computed: {
    successMessage () {
      return this.message.sort( () => .5 - Math.random() )[0]
    }
  },

  methods: {
    startTraining () {
      if (this.stage === 1) return

      this.stage = 1
      this.countdown = this.data.fields.countdown
      this.form.repeats = this.form.repeats + 1
      if (this.$refs.playerStart) {
        this.$refs.playerStart.play()
      }
      var interval = setInterval(() => {
        if (this.countdown > 1) {
          this.countdown = this.countdown - 1
          if (this.countdown <= 3 && this.$refs.playerCue) {
            this.$refs.playerCue.play()
          }
          if (this.countdown === Math.floor(this.data.fields.countdown / 2) && this.data.fields.swapInstructions ) {
            this.$refs.playerCue.play()
          }
        } else {
          if (this.$refs.playerEnd) {
            this.$refs.playerEnd.play()
          }
          clearInterval(interval)
          if (this.data.fields.disableRating) {
            setTimeout(() => {
              this.next()
            }, 10)
          } else {
            this.stage = 2
          }
        }
      }, 1000)

    },
    trainMore() {
      this.form.trainMore = true
      this.$store.dispatch('STORE_RESPONSE_NEW', { response: this.form })
      this.$router.go(-2)
    },
    changeWorkout () {
      this.$router.go(-1)
    },
    startVideo () {
      this.form.videoPlays += 1
      this.overlay = true
      this.getVideoEmbed(this.data.fields.youTubeEmbedCode)
    },
    quitOverlay () {
      this.overlay = false
    },
    getVideoEmbed (ytID, ytStartTime) {
      return `
        <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/${ytID}?start=${ytStartTime}&autoplay=0&showinfo=0&iv_load_policy=3&modestbranding=0&origin&rel=0&controls=1&autohide=1${process.env.VUE_APP_BASE_URL}" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>      `
    },
    playAudio () {
      if (this.$refs.player) {
        if (!this.$refs.player.paused) {
          this.isPlaying(false)
          this.$refs.player.pause()
        } else {
          this.$refs.player.play()
          this.isPlaying(true)
        }
      }
    },
    toggleThis (item, key) {
      this.rand = Math.random()
      this.form.reason = item
      setTimeout(() => {
        this.next()
      }, 50)
    },
    isPlaying (event) {
      this.playing = event.type === 'playing'
    },
    emitNext () {
      this.$emit('next', this.form)
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.calculateScores()
    },
    calculateScores () {
      this.locked = true
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.data.fields.formula ? [this.data.fields.formula] : '',
        selected: ['x'],
        options: ['x'],
        maxAnswers: 1,
        skip: this.$store.state.survey.noSkipWorkouts ? '' : String(`${this.data.fields.skip},${this.data.fields.skip}`)
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.feedback = scores.feedback
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    }
  },
  watch: {
    //form.rating < 4
    'form.rating': function (newVal, oldVal) {
      if (newVal > 3) {
        setTimeout(() => {
          this.next()
        }, 500)
      } else {
        this.stage = 3
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pausa-activa-wrapper {
  overflow-x: hidden;
}
.change-workout-wrapper {
  position: fixed;
  bottom: 2%;
  left: 0;
  right: 0;
  .change-workout-button {
    font-size: 17px;
    background-color: rgba($text-main, 0.8);
    border-color: black;
    @include breakpointHeight($square) {
      font-size: 15px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
.video-overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(black, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay-close-button-wrapper,
.repeat-button-wrapper,
.remarks-wrapper {
  position: fixed;
  top: 2px;
  right: 10px;
  z-index: 1001;
  opacity: 0.7;
  color: white;
  &:hover {
    cursor: pointer;
  }
}
.repeat-button-wrapper {
  right: 5px;
  top: 3px;
}
.remarks-wrapper {
  width: 100%;
  top: 20px;
  right: 0;
  left: 0;
  opacity: 0.99;
  font-style: italic;
  font-size: 18px;
  h1 {
    margin-bottom: 5px;
    font-size: 28px;
  }
  p {
    font-size: 23px;
  }
  .small {
    font-size: 18px;
  }
  @include breakpointHeight($square) {
    line-height: 1;
  }
}
.full-bg-Cardio {
  .remarks-wrapper {
    h1, p {
      color: $text-main;
    }
  }
  .countdown {
    color: $text-main;
  }
}
.enlarge-icon {
  transform: scale(2.5);
  margin-right: -6px;
  margin-bottom: 40px;
  margin-top: 20px;
  @include breakpoint($tablet) {
    transform: scale(3.5);
  }
  @include breakpoint($desktop) {
    transform: scale(2.5);
  }
}

.reasons {
  .option-box {
    min-height: 60px;
    background: #f0f0f0;
    padding: 6% 3%;
    margin: 1%;
    border-radius: 5px;
    width: 42%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 1.1;
    @include breakpointHeight($square) {
      font-size: 18px;
      min-height: 40px;
    }
  }
}

.yt-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}

.hide {
  opacity: 0.0001;
}
.full-bg-Strength {
  background: linear-gradient(0deg, rgba(#ED7287,1) 0%, rgba(#942193,1) 100%);
}
.full-bg-Cardio {
  background: linear-gradient(0deg, rgba(#FF2F92,1) 0%, rgba(#FFFC79,1) 100%);
}
.full-bg-Mind {
  background: linear-gradient(0deg, rgba(#a4db19,1) 0%, rgba(#00ddef,1) 100%);
}
.full-bg-Mobility {
  background: linear-gradient(0deg, rgba(#D783FF,1) 0%, rgba(#00FDFF,1) 100%);
}
.countdown-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 50vh;
  max-height: 50vh;
  position: absolute;
  width: 100%;
  top: 60px;
  top: 20vh;
  height: 30vh;
  max-height: 30vh;
  &.on-end {
    max-height: 100vh;
    height: 100vh;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.full-height {
    height: 70vh;
    max-height: 70vh;
    display: flex;
    justify-content: center;
  }
  @include breakpointHeight($square) {
    top: 27vh;
  }
}
.countdown {
  flex: 1;
  font-size: 175px;
  color: rgba(255,255,255,0.8);
  font-weight: bold;
  font-family: inherit;
  position: absolute;
  width: 100%;
}
.action-button-wrapper {
  position: absolute;
  width: 96%;
  margin: auto;
  flex: 1;
}
.go-button {
  background-color: rgba(white, 0.9);
  border: 2px solid white;
  box-shadow: 0px 0px 100px rgba(black, 0.3);
  font-size: 25px;
  color: rgba($text-main, 0.8);  
  width: 160px;
  height: 160px;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: rgba(white, 0.8);
  }
  @include breakpointHeight($square) {
    width: 120px;
    height: 120px;
    font-size: 17px;
  }
}
.next-button,
.train-more-button {
  font-size: 20px;
  background-color: rgba(white, 0.9);
  border: 2px solid white;
  color: rgba($text-main, 0.8);
  &:hover {
    background-color: rgba(white, 0.8);
  }
}
.train-more-button {
  margin-top: 20px;  
}
.pausa-picture {
  width: 120%;
  height: 0;
  padding-bottom: 56.25%;
  background-position: center center;
  position: absolute;
  bottom: 20%;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 71%;
  bottom: 8%;
  transform: translateX(-10%) scale(1.1);
  @include breakpointHeight($square) {
    bottom: 1%;
    width: 100%;
    transform: translateX(0) scale(0.8);
  }
}
.pausa-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.video-hint {
  text-decoration: underline;
  padding-left: 5px;
}



.grid-wrapper{
  .binary-box {
    padding-bottom: calc(45% * 4/5);
    @include breakpointHeight($square) {
      transform: scale(0.9);
    }
  }  
}

.content {
  margin-top: 0;
  align-items: flex-start;
}
.has-image {
  .content {
    margin-bottom: 0;
  }
}
h2.white {
  color: rgba(white, 0.9);
  font-size: 30px;
}
h3.white {
  color: rgba(white, 0.9);
  font-size: 26px;
  margin-top: 40px;
}
</style>
<style lang="scss">
.yt-embed iframe,
.yt-embed object,
.yt-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>